import React, { useEffect, useState } from 'react'
import { useContext } from 'react';
import { serverGet } from '../../../../middleware/http';
import { apiConst, appConst, resourceConst } from '../../../../services/constant/constant';
//import { useAccountSettings } from '../../../services/Context/AccSettingContext';
import { useLanguageRes } from '../../../../services/Context/LanguageContext';
import { useManageResource } from '../../../../services/Context/ManageResourceContext';
import ProgressContext from '../../../../services/utils/progress';
import { getSearchParams, removeSearchParam, updateSearchParams } from '../../../../services/utils/validation';
import CustomForm from './customForm';
import FormList from './FormList';
import { useLayoutEffect } from 'react';

export default function Forms({ level, formShowState, encounter, tabData, areaId, tabFormId, handleEncounterChange }) {
    const [showForm, setShowForm] = useState(formShowState || getSearchParams("frmid"));
    const [showMultipleForms, setShowMultipleForms] = useState(false);
    const [formId, setFormId] = useState(null); // CHOOSED MULTIPLE ENTRY FORM ID
    const [isWriteForms, setIsWriteForms] = useState(false); // CHOOSED MULTIPLE ENTRY IS WRITE
    const [selectedFormId, setSelectedFormId] = useState(null);
    const [selectedFormInputId, setSelectedFormInputId] = useState(null);
    const [resourceAction, setResourceAction] = useState([]);
    const [formsList, setFormsList] = useState([]);
    const { progParams } = useContext(ProgressContext);
    const { getLanguageContent } = useLanguageRes();
    const { resourceList, getCurrentResourceAction, actionExists } = useManageResource();

    const handleMultipleForms = () => {
        setShowMultipleForms(!showMultipleForms)
    }


    const handleAddNew = () => {
        removeSearchParam("frminputid");
        setSelectedFormInputId(null);
        updateSearchParams("frmid", formId);
        //setSelectedFormId(formId);
        setShowForm(true);
    }

    const toggeleForm = () => {
        setShowForm((prev) => !prev);
    }


    useEffect(() => {

        if (level == "encounter-forms") {
            if (tabData?.isAllowMultiple) {
                setShowForm(false);
                setShowMultipleForms(true);
                setFormId(tabFormId)
                //setSelectedFormId(tabFormId);

            } else {
                setShowForm(true);
                //setSelectedFormId(tabFormId);
                //setSelectedFormInputId(null);
                setShowMultipleForms(false);
                setFormId(tabFormId)
            }
        }


    }, [tabData])


    useEffect(() => {
        const _action = getCurrentResourceAction(tabData?.code, tabData?.parentId);
        setResourceAction(_action);

    }, [resourceList])


    useEffect(() => {
        if (formId && Array.isArray(formsList) && formsList?.length > 0) {
            const temp = formsList?.find((f) => f.formId == formId)?.write;
            setIsWriteForms(temp);
        }

    }, [formId, formsList])

    //useEffect(() => {
    //    return (() => {
    //        setShowForm(null);
    //        setShowMultipleForms(null);
    //        setSelectedFormId(null);
    //        setFormId(null);
    //    })
    //}, [])
    //console.log(resourceAction);

    //;

    const getForms = async () => {
        try {
            // progParams(true);
            //setTimeout(() => {
            //    progParams(true);

            //}, 200)
            let speciality_id = getSearchParams("spid") || encounter?.specialityId;
            let encounter_id = getSearchParams("eid");
            let fmid = getSearchParams("fmid"); // family Member Id
            if (!speciality_id && encounter_id) {
                const res = await serverGet(apiConst.getencounter + encounter_id);
                if (res?.status == 1 && res.data?.specialityId)
                    speciality_id = res.data?.specialityId;
            }
            let member_id = localStorage.getItem("memberId");
            if (!member_id) {
                const patient_res = await serverGet(apiConst.patientinfo);
                if (patient_res?.status == 1 && patient_res?.data && patient_res?.data?.memberId)
                    member_id = patient_res?.data?.memberId;
            }
            setFormsList("loading");

            const url = (level == "encounter-forms" || level == "intake-flow") ? `${apiConst.formsList}${fmid ? fmid : member_id}?sid=${speciality_id}&areaid=${areaId}&eid=${encounter_id}` : `${apiConst.formsList}${member_id}?areaid=${areaId}`

            const res = await serverGet(url);
            if (res?.data != null && res.status == 1) {
                setFormsList(res?.data);
                return res?.data;
            } else {
                setFormsList([]);
            }
        } catch (error) {
            console.log(error);
        } finally {

        }

    }

    useEffect(() => {
        document.title = "Forms"
        setTimeout(() => {
            getForms();
        }, 400)
    }, [])

    useEffect(() => {
        document.title = "Forms"


        if (level == "encounter-forms") {
            getForms();
        }
    }, [setShowForm, showMultipleForms])


    return (
        <>
            <div className={encounter ? "card border-0" : ""}>
                <div className={encounter ? "card-body" : ""}>
                    {(level == "patient-forms" || level == "encounter-forms" ) && (
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            {(Array.isArray(formsList) && formsList?.length > 0) && (
                                <div>
                                    <h4 className={`${level == "encounter-forms" ? "fs-6 card-title text-uppercase fw-bold" : ""} m-0`}>{showMultipleForms || showForm ? formsList?.find((f) => f.formId == formId)?.clientTitle : getLanguageContent("frms")}</h4>
                                </div>
                            )}

                            {(showMultipleForms && !showForm) && (
                                <div className="ms-auto">
                                    {(tabData?.encounter?.statusId != 3 && tabData?.encounter?.statusId != 4 && (level == "encounter-forms" ? tabData?.write && isWriteForms : isWriteForms)) && (
                                        <button className="btn btn-primary" onClick={handleAddNew}>Add new</button>
                                    )}
                                    {(level == "patient-forms") && <button className="btn btn-secondary ms-2" onClick={() => handleMultipleForms()}>Back</button>}
                                </div>
                            )}
                        </div>
                    )}

                    <div className={encounter ? "" : "card border-0"}>
                        <div className={encounter ? "" : "card-body"}>
                            {!showForm && <FormList handleEncounterChange={handleEncounterChange} setSelectedFormId={setSelectedFormId} setSelectedFormInputId={setSelectedFormInputId} toggeleForm={toggeleForm} level={level} formsList={formsList} handleMultipleForms={handleMultipleForms} showMultipleForms={showMultipleForms} setFormId={setFormId} formId={formId} />}
                            {showForm && <CustomForm createAction={actionExists(resourceAction, resourceConst?.actions?.create)} getForms={getForms} selectedFormId={selectedFormId} setSelectedFormId={setSelectedFormId} setSelectedFormInputId={setSelectedFormInputId} selectedFormInputId={selectedFormInputId} toggeleForm={toggeleForm} level={level} formsList={formsList} encounter={encounter} tabData={tabData} showMultipleForms={showMultipleForms} />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
